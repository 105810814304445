import { useAppSelector } from '@hooks/useAppSelector';
import { useNeighbor } from '@hooks/useNeighbor';
import { SIGN_UP_COIN_PACKAGE_CODE } from '@utils/constants';
import { CoinPackCode } from '@utils/types';

export const useActiveCoinPack = (): {
  activeCoinPackCode?: typeof SIGN_UP_COIN_PACKAGE_CODE;
  activeCoinPackImagePath?: string;
  activeSignUpCoinPackCodes?: typeof SIGN_UP_COIN_PACKAGE_CODE;
  isCoinPackCampaignActive: boolean;
  isSignUpCoinPackageActive: boolean;
  showCoinPackPreAnnouncement: boolean;
} => {
  const {
    campaign: { campaigns },
  } = useAppSelector((state) => ({
    campaign: state.campaign,
  }));
  const { neighbor } = useNeighbor();

  // When a coin pack is prioritized & purchasable, it becomes "active"
  const activeCoinPackCode =
    ([...(campaigns?.coin_pack?.package_codes || [])].find(
      (k) => neighbor?.purchase_coin_packages?.[k]?.can_purchase
    ) as CoinPackCode) || null;

  const isSignUpCoinPackageActive = Object.values(
    neighbor?.purchase_coin_packages || {}
  ).some((_package) => _package.can_purchase);

  const activeSignUpCoinPackCodes =
    neighbor?.purchase_coin_packages &&
    Object.entries(neighbor?.purchase_coin_packages)
      .filter(([key, value]) => value.can_purchase === true)
      .map(([key]) => key);

  const isCoinPackCampaignActive =
    (campaigns?.coin_pack?.status?.active &&
      campaigns?.coin_pack?.package_codes?.includes(activeCoinPackCode)) ||
    false;

  const showCoinPackPreAnnouncement = !!(
    campaigns?.coin_pack?.status?.can_be_eligible &&
    Date.now() < Date.parse(campaigns?.coin_pack?.start_at) &&
    Date.now() >= Date.parse(campaigns?.coin_pack?.pre_start_at)
  );

  const activeCoinPackImagePath = `/images/campaign/coin-package/${campaigns?.coin_pack?.code}/`;

  return {
    activeCoinPackCode,
    activeCoinPackImagePath,
    activeSignUpCoinPackCodes,
    isCoinPackCampaignActive,
    isSignUpCoinPackageActive,
    showCoinPackPreAnnouncement,
  };
};
