import { useMemo } from 'react';
import { VipRank } from '@services/hafh/types/generated';
import {
  BASIC_CODE,
  COUNTRY_IDS,
  FREE_PLAN_KIND,
  SNOOZE_PLAN_KIND,
} from '@utils/constants';
import { isEmpty } from '@utils/utils';
import { useAppSelector } from './useAppSelector';
import useRegistrationCountry from './useRegistrationCountry';

export const useNeighbor = () => {
  const { loadingNeighbor, neighbor } = useAppSelector((state) => ({
    loadingNeighbor: state.loadingNeighbor,
    neighbor: state.neighbor,
  }));

  const { isRegistrationCountryJapan } = useRegistrationCountry();

  const isLoggedIn = useMemo(
    () => !loadingNeighbor && !isEmpty(neighbor),
    [loadingNeighbor, neighbor]
  );

  const isSnooze = useMemo(
    () => neighbor.neighbor_plans?.[0].kind === SNOOZE_PLAN_KIND,
    [neighbor.neighbor_plans]
  );

  const isFreePlan = useMemo(
    () => neighbor?.neighbor_plans?.[0].kind === FREE_PLAN_KIND,
    [neighbor.neighbor_plans]
  );

  const isBasicPlan = useMemo(
    () => neighbor?.neighbor_plans?.[0].code === BASIC_CODE,
    [neighbor.neighbor_plans]
  );

  const vipRank: VipRank = useMemo(
    () => neighbor.vip_rank ?? 'regular',
    [neighbor]
  );

  const isVip = useMemo(
    () => !!neighbor.vip_rank && neighbor.vip_rank !== 'regular',
    [vipRank]
  );
  const isJapaneseNationality = useMemo(
    () => neighbor.country?.id === COUNTRY_IDS.japan,
    [neighbor]
  );

  const isKanjiNameRegistered = useMemo(
    () =>
      !!(
        isRegistrationCountryJapan &&
        neighbor.first_name_kanji &&
        neighbor.last_name_kanji
      ),
    [neighbor]
  );

  const isEKycComplete = useMemo(
    () => ['success', 'manually_approved'].includes(neighbor.ekyc_status ?? ''),
    [neighbor.ekyc_status]
  );
  const isEKycPendingResult = useMemo(
    () => neighbor.ekyc_status === 'pending_result',
    [neighbor.ekyc_status]
  );

  return {
    isBasicPlan,
    isEKycComplete,
    isEKycPendingResult,
    isFreePlan,
    isJapaneseNationality,
    isKanjiNameRegistered,
    isLoggedIn,
    isSnooze,
    isVip,
    loadingNeighbor,
    neighbor,
    vipRank,
  };
};
